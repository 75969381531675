<template>
  <DataTable
      ref="tree"
      v-model:selection="selectedAlarmPointItem"
      :dataKey="tableDataKey"
      :lazy="true"
      :page="apCurrentPage"
      :paginator="true"
      :paginatorTemplate="'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'"
      :rowHover="true"
      :rows="alarmPointRows"
      :scrollable="true"
      :totalRecords="apTotalRecords"
      :value="alarmPoints"
      class="tight"
      scrollHeight="72vh"
      selectionMode="single"
      stripedRows
      @page="selectTablePage($event)"
      @row-click="selectAlarmPoint($event.data);"
  >
    <Column
        v-if="expandSidebarDialog"
        :header="_t('label_Icon')"
        :hidden="!handleCheckRow('icon')"
        :style="'padding-left: 10px!important; max-width: 55px;'"
        field="icon"
    >
      <template #body="slotProps">
        <i :class="`icon_medium p-mr-2 ${JSON.parse(slotProps?.data.icon)?.name || 'mdi mdi-alert-outline'}`"
           :style="`font-size: 24px; color: white; background-color: ${JSON.parse(slotProps?.data.icon)?.color || 'rgb(152, 152, 152)'};`"/>
      </template>
    </Column>

    <Column
        field="name"
        :header="_t('Name')"
        :style="'width:' + (100 / alarmPointValues.length) + '%; word-break: break-all; min-height: 38px;' + (page === 'rule' ? 'padding-left: 0!important;' : '')"
    >
      <template #body="slotProps">
             <span v-tooltip.bottom="slotProps.field" class="wrap"
                   style="word-break: break-all;"
                   :style="`${page === 'rule' ? 'padding-left: 0px;' : 'padding-left: 5px'}`"
             >
                {{
                 selectedTab === 'name' ?
                     slotProps.data.name :
                     selectedTab !== 'systemId' ?
                         slotProps.data.systemId :
                         slotProps.data.alarmPointId
               }}
          </span>
      </template>
    </Column>

    <Column
        v-if="expandSidebarDialog"
        :hidden="!handleCheckRow('alarmId')"
        :header="_t('label_alarm_id')"
        field="alarmPointId"
        :style="'width:' + (100 / alarmPointValues.length) + '%; word-break: break-all; min-height: 38px;' + (page === 'rule' ? 'padding-left: 0!important;' : '')"
    >
      <template #body="slotProps">
            <span
                style="word-break: break-all;"
                :style="`${page === 'rule' ? 'padding-left: 0px;' : 'padding-left: 5px'}`"
            >
              {{ slotProps.data.alarmPointId }}
            </span>

      </template>
    </Column>

    <Column
        v-if="expandSidebarDialog"
        :header="_t('label_system_id')"
        :hidden="!handleCheckRow('systemId')"
        :style="'width:' + (100 / alarmPointValues.length) + '%; word-break: break-all; min-height: 38px;' + (page === 'rule' ? 'padding-left: 0!important;' : '')"
        field="systemId">
      <template #body="slotProps">
            <span
                style="text-align: center; word-break: break-all;"
                :style="`${page === 'rule' ? 'padding-left: 0px;' : 'padding-left: 5px'}`"
            >
              {{ slotProps.data.systemId }}
            </span>

      </template>
    </Column>

    <Column
        v-if="expandSidebarDialog"
        :hidden="!handleCheckRow('alarmPlan')"
        :header="_t('label_alarm_plan')"
        field="alarmPointPlan"
        :style="'width:' + (100 / alarmPointValues.length) + '%; word-break: break-all; min-height: 38px;' + (page === 'rule' ? 'padding-left: 0!important;' : '')"
    >
      <template #body="slotProps">
            <span
                style="word-break: break-all;"
                :style="`${page === 'rule' ? 'padding-left: 0px;' : 'padding-left: 5px'}`"
            >
              {{ slotProps.data.alarmPlanName }}
            </span>

      </template>
    </Column>

    <Column
        v-if="expandSidebarDialog"
        :header="_t('ALARM_POINT_ROTATIONAL_CAL')"
        :hidden="!handleCheckRow('schedule')"
        :style="'width:' + (100 / alarmPointValues.length) + '%; word-break: break-all; min-height: 38px;' + (page === 'rule' ? 'padding-left: 0!important;' : '')"
        field="schedule"
    >
      <template #body="slotProps">
            <span
                style="word-break: break-all;"
                :style="`${page === 'rule' ? 'padding-left: 0px;' : 'padding-left: 5px'}`"
            >
              {{ slotProps.data.rotationalCalendarName }}
            </span>
      </template>
    </Column>

    <Column
        v-if="expandSidebarDialog"
        :header="_t('ALARMSTATUS')"
        :hidden="!handleCheckRow('alarmStatus')"
        :style="'width:' + (100 / alarmPointValues.length) + '%; word-break: break-all; min-height: 38px;' + (page === 'rule' ? 'padding-left: 0!important;' : '')"
        field="alarmPointStatus">
      <template #body="slotProps">
            <span
                style="text-align: center; word-break: break-all;"
                :style="`${page === 'rule' ? 'padding-left: 0px;' : 'padding-left: 5px'}`"
            >
              {{ slotProps.data.alarmPointStatus }}
            </span>
      </template>
    </Column>

    <Column
        v-if="expandSidebarDialog"
        :hidden="!handleCheckRow('mode')"
        :style="'max-width: 10%; word-break: break-all; min-height: 38px;' + (page === 'rule' ? 'padding-left: 0!important;' : '')"
        header="Mode"
        field="acknowledgeMode"/>
    <Column
        v-if="expandSidebarDialog"
        :header="_t('ORGANIZATION')"
        :hidden="!handleCheckRow('orgName')"
        :style="'width:' + (100 / alarmPointValues.length) + '%; word-break: break-all; min-height: 38px;' + (page === 'rule' ? 'padding-left: 0!important;' : '')"
        field="orgName"
    >
      <template #body="slotProps">
            <span
                style="word-break: break-all;"
                :style="`${page === 'rule' ? 'padding-left: 0px;' : 'padding-left: 5px'}`"
            >
              {{ slotProps.data.orgName }}
            </span>

      </template>
    </Column>
  </DataTable>
</template>

<script>

// import {getOrganizationColumns} from "@/api/uiColumns";

export default {
  name: 'AlarmPointTable',
  props: {
    alarmPoints: {
      type: Array,
      default: () => [],
    },
    alarmPointValues: {
      type: Array,
      default: () => [],
    },
    tableDataKey: {
      type: null,
      default: null,
    },
    selectedTab: {
      type:String,
      default: 'name'
    },
    apCurrentPage: {
      type: Number,
      default: 0,
    },
    apTotalRecords: {
      type: Number,
      default: 0,
    },
    totalRecords: {
      type: Number,
      default: 0,
    },
    alarmPointRows: {
      type: Number,
      default: 100,
    },
    expandSidebarDialog: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    getAlarmPoints: {
      type: Function,
    },
    getAlarmPlans: {
      type: Function,
    },
    loadInstructions: {
      type: Function,
    },
    getAlarmPoint: {
      type: Function,
    },
    setApCurrentPage: {
      type: Function,
    },
    page: {
      type: String,
    },
  },
  emits: ['update:selectedAlarmPointItem', 'update:loading', 'update:apCurrentPage'],
  data() {
    return {
      selectedAlarmPointItem: null,
    }
  },
  methods: {
    selectTablePage(item) {
      // this.loading = true
      this.$emit('update:loading', true)
      this.$emit('setApCurrentPage', item.page)
      this.getAlarmPoints();
    },
    selectAlarmPoint(data) {
      if (this.page !== 'rule') {
        this.$router.push({
          params: {
            id: data.id
          }
        });
        this.getAlarmPlans()
        this.loadInstructions()
        if (!data || !data.id) {
          return
        }
        this.getAlarmPoint(data.id)
      } else {
        this.$router.push({path: `/alarm-points/${data.id}?`})
      }
    },
    handleCheckRow(name) {
      const foundedValue = this.alarmPointValues.find(({value}) => value === name)
      return !!foundedValue?.checked?.length;
    },
  }
}

</script>

<style lang="scss" scoped>
::v-deep(.p-datatable) {
  .p-datatable-header {
    padding: 10px;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 10px;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
    padding: 10px;
    background-color: #f8f9fa;
    padding-left: 10px !important;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
    padding: 5px;
    font-weight: 1500;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}


</style>
